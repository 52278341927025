import React, { useEffect, useState } from 'react';
import Goback from '../../../components/Goback';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
// import SearchAddContainer from '../../../components/SearchAddContainer';
import ClientsTable from '../../../components/table/ClientsTable';
import ClientsDetailSideModal from '../../../components/modals/ClientsDetailSideModal';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAllRessellersClients,
	getResellablePass,
	// resellerClientsByPass,
} from '../../../features/resellers/resellersSlice';
import Spinner from '../../../components/PageLoader';
import PaginationWithApi from '../../../components/PaginationWithApi';

const Clients = () => {
	const navigate = useNavigate();
	// const [selectedFilter, setSelectedFilter] = useState('');
	// const [userSearch, setUserSearch] = useState('');
	const [openSideModal, setOpenSideModal] = useState(false);
	const [selectedClient, setSelectedClient] = useState({});

	// const [paginateClients, setPaginateClients] = useState([]);
	// const [paginatedClients, setPaginatedClients] = useState([]);
	// const [page, setPage] = useState(0);

	const [currentPage, setCurrentPage] = useState(0); // Current page

	const {
		resellersClients,
		submitingFrom,
		// resellerPasses
	} = useSelector((store) => store.resellers);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getResellablePass());
	}, []);
	// const allClients = resellersClients?.content || resellersClients?.data || [];

	// useEffect(() => {
	// 	if (selectedFilter?.id) {
	// 		if (selectedFilter?.id === 'all') {
	// 			dispatch(getAllRessellersClients(10, 0));
	// 		} else {
	// 			dispatch(resellerClientsByPass(selectedFilter?.id));
	// 		}
	// 	}
	// }, [selectedFilter]);

	const handleNext = () => {
		if (!resellersClients?.last) {
			const nextPage = currentPage + 1;
			setCurrentPage(nextPage);
		}
	};

	const handlePrev = () => {
		if (currentPage > 0) {
			const prevPage = currentPage - 1;
			setCurrentPage(prevPage);
		}
	};

	useEffect(() => {
		dispatch(getAllRessellersClients(currentPage));
	}, [currentPage]);

	// const clinetsNameFilter = allClients.filter((user) => {
	// 	if (
	// 		user?.firstName?.toLowerCase().includes(userSearch.toLowerCase()) ||
	// 		user?.lastName?.toLowerCase().includes(userSearch.toLowerCase())
	// 	) {
	// 		return user;
	// 	}
	// });

	// useEffect(() => {
	// 	if (clinetsNameFilter) {
	// 		setPaginateClients(paginate(clinetsNameFilter, 7));
	// 	}
	// }, [clinetsNameFilter?.length]);

	// useEffect(() => {
	// 	if (paginateClients) {
	// 		setPaginatedClients(paginateClients[page]);
	// 	}
	// }, [paginateClients, page]);

	return (
		<>
			{submitingFrom ? (
				<>
					<Spinner />
				</>
			) : (
				<>
					{' '}
					<div className="clients">
						<Goback linkTo={'/enterprise'} />
						<div className="clients__header header--btn">
							<div>
								<h4>Clients</h4>
								<p className="mini-text">
									Manage your client list and assign vouchers
								</p>
							</div>
							<div className="enterprise__header--btn">
								<Button
									variant={'primary'}
									size={'md'}
									onClick={() => {
										navigate('/add-clients');
									}}
								>
									<span className="add">+</span>
									Add New Clients
								</Button>
							</div>
						</div>
						<div className="clients__table mt-16">
							<div className="clients__table--header">
								<h4>Client Information</h4>
								{/* <SearchAddContainer
									changeInput={(e) => setUserSearch(e.target.value)}
									filterOptions={resellerPasses?.data?.map((item) => {
										return { name: item?.name, id: item?.id };
									})}
									selectedFilter={selectedFilter}
									setSelectedFilter={setSelectedFilter}
									flipSides={true}
									isFilterObject={true}
									addAllfilter={true}
								/> */}
							</div>
							<div className="clients__table--body mt-24">
								<ClientsTable
									setOpenSideModal={setOpenSideModal}
									data={resellersClients?.content}
									setSelectedClient={setSelectedClient}
								/>

								<PaginationWithApi
									data={resellersClients}
									handleNext={handleNext}
									handlePrev={handlePrev}
								/>
							</div>
						</div>
						{openSideModal && (
							<ClientsDetailSideModal
								openSideModal={openSideModal}
								setOpenSideModal={setOpenSideModal}
								selectedClient={selectedClient}
							/>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default Clients;
