import React from 'react';
import Icon from './Icon';

const PaginationWithApi = ({ handlePrev, handleNext, data }) => {
	return (
		<div className="employee__table--pagination">
			<p className="pageNumber">
				{`page ${data?.number + 1 ? data?.number + 1 : 1} of ${
					data?.totalPages ? data?.totalPages : '1'
				}`}
			</p>
			<div className="pages">
				<div className="next">
					<Icon width="9" height="14" id="left-caret" />
					<span onClick={handlePrev}>Prev</span>
				</div>
				{/* <div className="numbers">
					{employees?.map((item, index) => {
						return (
							<span
								key={index}
								className={`${index === page ? 'active' : null}`}
								onClick={() => handlePage(index)}
							>
								{index + 1}
							</span>
						);
					})}
				</div> */}
				<div className="next">
					<span onClick={handleNext}>NEXT</span>
					<Icon width="9" height="14" id="right-caret" />
				</div>
			</div>
		</div>
	);
};

export default PaginationWithApi;
