import React, { useEffect, useState } from 'react';
import EnterpriseCard from '../../../components/EnterpriseCard';
import EnterpriseTransactions from '../../../components/table/EnterpriseTransactions';
import skeletonLoading from '../../../assets/svgs/nolist2.png';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAllRessellersClients,
	getResellablePass,
	resellerApisKey,
	resellerTransactions,
} from '../../../features/resellers/resellersSlice';
import ActiveBenefits from '../../../components/ActiveBenefits';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import toast from 'react-hot-toast';
import EligibleGymSideModal from '../../../components/modals/EligibleGymSideModal';
import Slider from 'react-slick';
import Spinner from '../../../components/PageLoader';
import { getUserFromLocalStorage } from '../../../utils/localstorage';
import { useNavigate } from 'react-router-dom';
// import PlanListTable from '../../../components/table/PlanListTable';

const sections = ['Transactions', 'API Credentials'];
function SampleNextArrow(props) {
	const { onClick } = props;
	return <div className="nextBtn" onClick={onClick} />;
}

function SamplePrevArrow(props) {
	const { onClick } = props;
	return <div className="prevBtn" onClick={onClick}></div>;
}
const EnterPrisePartner = () => {
	const [isVisible, setIsVisible] = useState(false);
	const [isCopied, setIsCopied] = useState(false);
	const [openSideModal, setOpenSideModal] = useState(false);
	const [selectedPass, setSelectedPass] = useState(null);
	const [sectionSelected, setSelectionSelected] = useState('Transactions');
	const [apiKey, setApiKey] = useState('');
	// const [resetApiKey, setResetApiKet] = useState('');
	const notifySuccess = (message) => toast.success(message);
	const navigate = useNavigate();
	const userDetails = getUserFromLocalStorage();
	const {
		resellerPasses,
		transations,
		apiKeyForResellers,
		apiKeyLoading,
		isLoading,
		resellersClients,
	} = useSelector((store) => store.resellers);

	const mainSliderSettings = {
		arrows: true,

		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		nextArrow: <SampleNextArrow className={'nextBtn'} />,
		prevArrow: <SamplePrevArrow className={'nextBtn'} />,
		appendDots: (dots) => <ul className="dotss">{dots}</ul>,
		// centerPadding: '10px',

		// customPaging: () => <div className="auth__aside-slider-dots"></div>,
	};

	console.log(resellersClients?.totalElements);

	const dispatch = useDispatch();
	useEffect(() => {
		Promise.all([
			dispatch(getResellablePass()),
			dispatch(resellerTransactions()),
			dispatch(getAllRessellersClients(0)),
		]);
	}, []);
	const handleCopy = async () => {
		try {
			await navigator.clipboard.writeText(apiKey);
			setIsCopied(true);
			notifySuccess('copied');
			setTimeout(() => setIsCopied(false), 2000); // Reset the copied state after 2 seconds
		} catch (error) {
			console.error('Failed to copy text: ', error);
		}
	};

	useEffect(() => {
		if (userDetails?.resellerAPIKey) {
			setApiKey(userDetails?.resellerAPIKey);
			console.log(userDetails);
		}
	}, [userDetails?.resellerAPIKey]);

	useEffect(() => {
		if (apiKeyForResellers?.data?.apikey) {
			userDetails.resellerAPIKey = apiKeyForResellers?.data?.apikey;
			localStorage.setItem('user', JSON.stringify(userDetails));
			setApiKey(apiKeyForResellers?.data?.apikey);
		}
	}, [apiKeyForResellers?.data?.apikey]);

	return (
		<>
			{isLoading ? (
				<Spinner />
			) : (
				<div className="enterprise">
					<div className="enterprise__header">
						<div className="enterprise__header--btn">
							{resellersClients?.totalElements > 0 ? (
								<>
									{' '}
									<Button
										variant={'primary'}
										size={'md'}
										onClick={() => {
											navigate('/clients');
										}}
									>
										My Clients
									</Button>
								</>
							) : (
								<>
									{' '}
									<Button
										variant={'primary'}
										size={'md'}
										onClick={() => {
											navigate('/add-clients');
										}}
									>
										<span className="add">+</span>
										Add New Clients
									</Button>
								</>
							)}
						</div>
						{resellerPasses?.data?.length < 3 ? (
							<div className="enterprise__header--cards">
								{resellerPasses?.data?.map((pass) => (
									<EnterpriseCard
										key={pass?.id}
										pass={pass}
										setOpenSideModal={setOpenSideModal}
										setSelectedPass={setSelectedPass}
									/>
								))}
							</div>
						) : (
							<div className="enterprise__header--card">
								<Slider {...mainSliderSettings}>
									{resellerPasses?.data?.map((pass) => (
										<EnterpriseCard
											key={pass?.id}
											pass={pass}
											setOpenSideModal={setOpenSideModal}
											setSelectedPass={setSelectedPass}
										/>
									))}
								</Slider>
							</div>
						)}
					</div>
					<div className="enterprise__body">
						<div className="enterprise__body--header">
							<ul>
								{sections.map((section) => (
									<li
										key={section}
										onClick={() => setSelectionSelected(section)}
										className={`${sectionSelected === section ? 'active' : ''}`}
									>
										{section}
									</li>
								))}
							</ul>
						</div>
						{sectionSelected === 'Transactions' && (
							<>
								{transations?.data?.length < 1 ? (
									<>
										<div className={'mt-40'}>
											<ActiveBenefits
												icon={skeletonLoading}
												text={`No transactions yet. Your transactions will be displayed here once you begin.`}
												centerHeight={false}
												dimension={true}
											/>
										</div>
									</>
								) : (
									<div className="enterprise__body--table">
										<EnterpriseTransactions data={transations?.data} />
										{/* <PlanListTable data={followers} /> */}
									</div>
								)}
							</>
						)}
						{sectionSelected === 'API Credentials' && (
							<>
								<div className="credentials">
									<h3>API Credentials</h3>
									<p>
										Get API keys to integrate Flance passes into your platform.
									</p>
									<div className="credentials--body">
										<h4>API Key</h4>
										<div className="input--field">
											<input
												value={apiKey}
												disabled
												className={`${isVisible ? 'visible' : ''}`}
											/>
											<span>
												<Icon
													id={isVisible ? 'eye-icon' : 'eye-close'}
													width={isVisible ? '16' : '24'}
													height={isVisible ? '15' : '24'}
													onClick={() => setIsVisible((visible) => !visible)}
												/>
											</span>
										</div>
										<div className="btn">
											<Button variant="primary" size="md" onClick={handleCopy}>
												{isCopied ? 'Copied!' : 'Copy'}
											</Button>
											<Button
												variant="secondary"
												size="md"
												block
												onClick={() => {
													dispatch(resellerApisKey());
												}}
												disabled={apiKeyLoading}
											>
												{apiKeyLoading ? 'Resting....' : 'Reset Secret key'}
											</Button>
										</div>
									</div>
								</div>
								<a
									href="https://documenter.getpostman.com/view/26212669/2sAXxTbqYa#9144e39f-ded0-466c-9b9c-bbbe7df209a6"
									target="_blank"
									rel="noopener noreferrer"
									className="docs-link mt-40"
								>
									API Documentation
									<span>
										<Icon width={10} height={14} id={'right-caret'} />
									</span>
								</a>
							</>
						)}
					</div>
					{openSideModal && (
						<EligibleGymSideModal
							openSideModal={openSideModal}
							setOpenSideModal={setOpenSideModal}
							selectedPass={selectedPass}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default EnterPrisePartner;
